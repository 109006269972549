import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { DarkModeService } from '../../services/dark-mode.service';
import { Subscription } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})

export class HeaderComponent {

  logoSrc: string;
  solutionsIconSrc: string;
  aboutIconSrc: string;
  myIntercargoIconSrc: string;
  isHovering1 = false;
  isHovering2 = false;
  isHovering3 = false;

  imageSrc: string;
  darkSrc: string;
  darkLabel: string;

  private subscription: Subscription;

  ngOnDestroy(): void {
    this.subscription.unsubscribe(); // Prevent memory leaks
    if (this.globalClickUnlistener) {
      this.globalClickUnlistener();
    }
  }

  public isNavbarCollapsed = true;
  private globalClickUnlistener: (() => void) | null = null;

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
  @ViewChild('mobileSearch') mobileSearch!: ElementRef;
  showMobileSearch: boolean = false;

  toggleSearch() {
    this.showMobileSearch = !this.showMobileSearch;
  }
  moonIcon: HTMLElement | null = null;

  isMobile = false;


  constructor(private darkModeService: DarkModeService, private router: Router, private renderer: Renderer2, private elRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
    this.logoSrc = 'path/to/logo.svg';
    this.solutionsIconSrc = 'path/to/solutions-icon.svg';
    this.aboutIconSrc = 'path/to/about-icon.svg';
    this.myIntercargoIconSrc = 'path/to/my-intercargo-icon.svg';
    this.imageSrc = ''
    this.darkSrc = 'bi-moon'
    this.darkLabel = 'Dark Mode'
    this.subscription = this.darkModeService.darkMode$.subscribe(isDarkMode => {
      this.imageSrc = isDarkMode ? '../../../assets/imgs/logos/DarkLogo.png' : '../../../assets/imgs/logos/Logo.png';
      this.darkSrc = isDarkMode ? 'bi-sun' : 'bi-moon';
      this.darkLabel = isDarkMode ? 'Light Mode' : 'Dark Mode';
    });
    if (isPlatformBrowser(this.platformId)) {
      // Initialize the isMobile value on component load
      this.checkIfMobile();
    }
  }


  @ViewChild('solutions') solutions!: MatExpansionPanel;
  @ViewChild('about') about!: MatExpansionPanel;
  @ViewChild('intercargo') intercargo!: MatExpansionPanel;
  ngOnInit() {
    this.globalClickUnlistener = this.renderer.listen('document', 'click', (event: Event) => {
      const clickedInside = this.elRef.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.handleLinkClick(this.solutions)
        this.handleLinkClick(this.about)
        this.handleLinkClick(this.intercargo)
        this.isNavbarCollapsed = true; // Collapse the navbar if clicked outside
        this.showMobileSearch = false;
      }
    });
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 1200;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }
  registerPage() {

    this.toggleNavbar();
    this.router.navigate(['/members-hub/open-an-account'], { queryParams: { path: 'megaMenu' } });
  }

  onMouseEnter() {
    if (isPlatformBrowser(this.platformId)) {
      this.moonIcon = document.querySelector('.bi-moon') as HTMLElement;
      if (this.moonIcon) {
        this.moonIcon.style.color = '#fff'; // Change to desired hover color
      }
      else {
        this.moonIcon = document.querySelector('.bi-sun') as HTMLElement;
        this.moonIcon.style.color = '#1D2B3B';
      }
    }
  }
  onMouseLeave() {
    if (isPlatformBrowser(this.platformId)) {
      this.moonIcon = document.querySelector('.bi-moon') as HTMLElement;
      if (this.moonIcon) {
        this.moonIcon.style.color = '#1D2B3B'; // Change to desired hover color
      }
      else {
        this.moonIcon = document.querySelector('.bi-sun') as HTMLElement;
        this.moonIcon.style.color = '#fff';
      }
    }
  }

  solutionMenuVisible = false;
  aboutMenuVisible = false;
  intercargoMenuVisible = false;
  SearchMenuVisible = false;

  hideMenuTimeout: any;

  showMegaMenu(name: string | null) {
    this.solutionMenuVisible = false;
    this.aboutMenuVisible = false;
    this.intercargoMenuVisible = false;
    this.SearchMenuVisible = false;

    if (name == 'Solutions') {
      this.solutionMenuVisible = true;
    }
    if (name == 'About Us') {
      this.aboutMenuVisible = true;

    }
    if (name == 'My Intercargo') {
      this.intercargoMenuVisible = true;
    }
    if (name == 'Search') {
      this.SearchMenuVisible = true;
    }
    this.clearHideTimeout();
  }

  scheduleHideMegaMenu(delay: number) {
    this.hideMenuTimeout = setTimeout(() => {
      this.solutionMenuVisible = false;
      this.aboutMenuVisible = false;
      this.intercargoMenuVisible = false;
    }, delay); // Adjust the delay to suit your needs
  }

  cancelHideMegaMenu() {
    this.clearHideTimeout();
  }

  clearHideTimeout() {
    if (this.hideMenuTimeout) {
      clearTimeout(this.hideMenuTimeout);
      this.hideMenuTimeout = null;
    }
  }

  toggleDarkMode(): void {
    this.darkModeService.toggleDarkMode();
    this.onMouseLeave();
  }
  handleLinkClick(panel: MatExpansionPanel, route?: string) {
    // Close the specific panel
    panel.close();
    this.toggleNavbar();

    // Optional route navigation if the route is provided
    if (route) {
      const queryParams = { path: 'megaMenu' };
      this.router.navigate([route], { queryParams })
    }
  }

  loginPage() {
    window.open('https://www-icots1.wisegrid.net/Portals/NEO', '_blank');
    // window.location.href = 'https://conexa.intercargo.com/Portals/NEO/Desktop#/login';
    // this.router('https://conexa.intercargo.com/Portals/NEO/Desktop#/login');
  }

  handleRoute(route: string) {
    this.router.navigate([route])
  }

  loginMobClicked() {
    this.loginPage()
  }

  hideSearch() {
    this.showMobileSearch = false
  }







  searchedString: string = ''
  searchResults: any

  content_metaData = [
    // FREIGHT Pages
    { url: '/freight/air', keywords: 'air freight, air cargo, air transportation, aviation logistics, express delivery, air shipping, global air transport, air logistics solutions', name: 'Air Freight' },
    { url: '/freight/sea', keywords: 'sea freight, ocean freight, maritime transport, container shipping, sea cargo, ocean shipping, global sea transport, maritime logistics solutions', name: 'Sea Freight' },
    { url: '/freight/road', keywords: 'road freight, road transport, trucking, ground transportation, road shipping, road cargo, land transport, logistics, road logistics solutions', name: 'Road Freight' },
    { url: '/freight/rail', keywords: 'rail freight, rail transport, railway logistics, train cargo, intermodal transport, rail shipping, rail logistics, rail logistics solutions', name: 'Rail Freight' },
    { url: '/freight/courier', keywords: 'courier services, express delivery, parcel delivery, same-day courier, logistics, fast delivery, courier transport, courier logistics solutions', name: 'Courier Services' },

    // SERVICES Pages
    { url: '/services/customs-clearance', keywords: 'customs clearance, customs brokerage, import/export, border control, tariff, customs documentation, regulatory compliance, customs logistics solutions', name: 'Customs Clearance' },
    { url: '/services/warehousing', keywords: 'warehousing, storage, inventory management, distribution, logistics, supply chain, warehouse services, warehousing solutions', name: 'Warehousing' },
    { url: '/services/project-cargo', keywords: 'project cargo, heavy lift, oversized cargo, specialized transport, complex logistics, customized shipping, project logistics, project cargo solutions', name: 'Project Cargo' },
    { url: '/services/aircraft-charter', keywords: 'aircraft charter, private jet, air charter, cargo charter, aviation services, bespoke air transport, specialized air freight, aircraft charter solutions', name: 'Aircraft Charter' },
    { url: '/services/dangerous-goods', keywords: 'dangerous goods, hazardous materials, hazmat, chemical transport, compliance, safety, regulated cargo, dangerous goods solutions', name: 'Dangerous Goods' },
    { url: '/services/perishable-goods', keywords: 'perishable goods, temperature-controlled, cold chain, fresh produce, refrigerated transport, perishables logistics, perishable goods solutions', name: 'Perishable Goods' },
    { url: '/services/same-day-delivery', keywords: 'same-day delivery, express delivery, fast shipping, urgent delivery, rapid transport, immediate delivery, courier services, same-day delivery solutions', name: 'Same Day Delivery' },
    { url: '/services/order-management', keywords: 'order management, supply chain management, logistics, inventory control, order processing, e-commerce, fulfillment, order management solutions', name: 'Order Management' },
    { url: '/services/onboard-courier', keywords: 'onboard courier, hand-carry, personal delivery, urgent shipments, time-critical logistics, hand-delivered cargo, onboard courier solutions', name: 'Onboard Courier' },
    { url: '/services/agency-representation', keywords: 'agency representation, logistics agency, freight forwarding, international logistics, trade representation, import/export, agency representation solutions', name: 'Agency Representation' },

    // INDUSTRIES Pages
    { url: '/industries/fashion', keywords: 'fashion logistics, apparel shipping, clothing transport, fashion supply chain, retail logistics, garment shipping, fashion industry solutions', name: 'Fashion Logistics' },
    { url: '/industries/consumer-goods', keywords: 'consumer goods, retail logistics, FMCG, product distribution, e-commerce logistics, consumer products, fast-moving goods, consumer goods solutions', name: 'Consumer Goods Logistics' },
    { url: '/industries/high-tech&electronics', keywords: 'high tech logistics, electronics shipping, technology transport, semiconductor logistics, tech supply chain, electronics distribution, high-tech logistics solutions, electrical, electric', name: 'High-Tech Electronics Logistics' },
    { url: '/industries/pharma&healthcare', keywords: 'pharma logistics, healthcare logistics, pharmaceutical transport, medical supplies, cold chain, healthcare supply chain, compliant transport, pharma & healthcare solutions', name: 'Pharma & Healthcare Logistics' },
    { url: '/industries/offshore&ship-spares', keywords: 'offshore logistics, ship spares, marine logistics, maritime transport, oil & gas logistics, vessel supplies, offshore supply chain, offshore & ship spares solutions', name: 'Offshore & Ship Spares Logistics' },
    { url: '/industries/aerospace&defence', keywords: 'aerospace logistics, defence transport, military logistics, aircraft parts, secure transport, defense supply chain, aviation logistics, aerospace & defence solutions', name: 'Aerospace & Defence Logistics' },
    { url: '/industries/ecommerce', keywords: 'e-commerce logistics, online retail, order fulfillment, e-commerce shipping, digital marketplace, last-mile delivery, parcel delivery, e-commerce solutions', name: 'E-commerce Logistics' },
    { url: '/industries/oil&gas', keywords: 'oil & gas logistics, energy transport, pipeline logistics, offshore transport, petroleum shipping, industrial logistics, oilfield services, oil & gas logistics solutions', name: 'Oil & Gas Logistics' },
    { url: '/industries/automotive', keywords: 'automotive logistics, vehicle transport, car shipping, automotive supply chain, auto parts, automotive industry logistics, automotive distribution, automotive logistics solutions', name: 'Automotive Logistics' },

    // ABOUT US Pages
    { url: '/about/mission-statement', keywords: 'mission statement, company mission, corporate vision, business values, company goals, organizational purpose, mission, corporate mission', name: 'Mission Statement' },
    { url: '/about/accreditations', keywords: 'accreditations, certifications, industry standards, compliance, quality assurance, recognized standards, ISO certified, accredited', name: 'Accreditations' },
    { url: '/about/our-partners', keywords: 'our partners, strategic alliances, business partnerships, collaboration, corporate partners, partnership network, partner companies', name: 'Our Partners' },
    { url: '/about/why-intercargo', keywords: 'why Intercargo, about Intercargo, company overview, our history, why choose us, our advantages, company profile, business strengths', name: 'Why Intercargo' },
    { url: '/about/how-to-ship', keywords: 'how to ship, shipping guide, shipping process, logistics process, how to send, shipment steps, freight process, shipping instructions', name: 'How to Ship' },

    // MY INTERCARGO Pages
    // LOGISTICS HUB Pages
    { url: '/MyIntercargo/conexa', keywords: 'Conexa, logistics platform, supply chain technology, transport management, logistics hub, digital logistics, Conexa solutions', name: 'Conexa' },
    { url: '/MyIntercargo/track-it', keywords: 'Track it, shipment tracking, cargo tracking, logistics tracking, real-time tracking, delivery tracking, track your shipment', name: 'Track It' },
    { url: '/MyIntercargo/sailing-schedules', keywords: 'sailing schedules, shipping schedules, maritime schedules, vessel schedules, shipping timetable, sailing timetable, sailing schedule', name: 'Sailing Schedules' },
    { url: '/MyIntercargo/flight-schedules', keywords: 'flight schedules, aviation schedules, flight timetable, air transport schedules, flight planning, airline schedules, flight information', name: 'Flight Schedules' },
    { url: '/MyIntercargo/incoterms', keywords: 'Incoterms, international commercial terms, shipping terms, trade terms, delivery terms, freight terms, Incoterms 2020', name: 'Incoterms' },

    // MEMBERS HUB Pages
    { url: '/members-hub/download-centre', keywords: 'download centre, resource download, documents, manuals, files, downloadable resources, access files, download area', name: 'Download Centre' },
    { url: '/members-hub/open-an-account', keywords: 'open an account, account registration, sign up, create an account, account setup, new account, registration process', name: 'Open An Account' },
    { url: '/members-hub/careers', keywords: 'careers, job opportunities, employment, work with us, job vacancies, career openings, job listings, career opportunities', name: 'Careers' },
    { url: '/members-hub/sustainability', keywords: 'sustainability, environmental impact, green logistics, eco-friendly practices, sustainability initiatives, corporate responsibility, sustainable logistics', name: 'Sustainability' },
    { url: '/members-hub/case-studies', keywords: 'case studies, success stories, client case studies, project case studies, business success, industry case studies, case study examples', name: 'Case Studies' },
    { url: '/members-hub/faqs', keywords: 'FAQs, frequently asked questions, help center, support questions, customer queries, common questions, help and support', name: 'FAQs' }
  ]

  searchBtnClicked() {

    if (this.searchedString != '') {
      this.hideSearch();
      this.router.navigate(['/search'], {
        queryParams: { searched: this.searchedString }
      });
    }

    this.searchResults = this.content_metaData.filter(page =>
      // page.keywords.toLowerCase().includes(this.searchQuery.toLowerCase())
      page.keywords.toLowerCase().includes(this.searchedString.toLowerCase())
    );
  }
}
