import { HttpClient } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { DarkModeService } from './services/dark-mode.service';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd, Event } from '@angular/router'; // Import Event
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  isOnline: boolean | undefined;
  hideHeaderFooter: boolean = false;

  constructor(
    private darkModeService: DarkModeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    this.darkModeService.darkMode$.subscribe((isDarkMode) => {
      if (isPlatformBrowser(this.platformId)) {
        document.body.classList.toggle('dark-mode', isDarkMode);
      }
    });

    // Subscribe to route changes
    this.router.events
      .pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd) // Type guard
      )
      .subscribe((event: NavigationEnd) => {
        // Check if the current route starts with 'same-day'
        this.hideHeaderFooter = event.url.startsWith('/same-day');
      });
  }

  title = 'intercargo_webapp.client';
}