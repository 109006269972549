import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetNewsService } from '../../services/get-news.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css', '../../shared/style.css'],
})
export class BlogComponent implements OnInit {
  articles: any[] = [];
  allArticles: any[] = [];
  defaultImage = '../../../assets/imgs/News1.png';
  currentPage: number = 1;
  articlesPerPage: number = 5;
  loading: boolean = true;

  rightImageInfo = {
    heading: `Latest updates and insights on the supply chain industry`,
    description: `Stay informed with real-time news, expert analysis, and trends.`,
    img: '../../../assets/imgs/BlogRight1.png',

    class: 'bg-light',
  };

  constructor(private router: Router, private newsService: GetNewsService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.getNews();
    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    this.title.setTitle(toCapitalize("Intercargo News") + " - Intercargo Logistics");

    this.meta.removeTag('name="author"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    // this.meta.removeTag('property="og:image"');
    this.meta.addTags([

      { name: 'author', content: 'Intercargo Logistics' },
      { property: 'og:title', content: 'Intercargo News' },
      {
        property: 'og:description', content: "Latest updates and insights on the supply chain industry."
      },
      // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
    ]);
  }

  getNews() {
    this.newsService.getNews().subscribe((response: any) => {
      if (response.hasOwnProperty('articles')) {
        this.allArticles = response.articles.results;
        this.allArticles = this.filterValidArticles(this.allArticles);
        this.updateArticles();
        this.loading = false;
      }
    });
  }

  filterValidArticles(articles: any[]): any[] {
    return articles.filter(
      (article) => article.image && article.url && article.title
    );
  }

  updateArticles() {
    const start = (this.currentPage - 1) * this.articlesPerPage;
    const end = start + this.articlesPerPage;
    this.articles = this.allArticles.slice(start, end);
  }

  changePage(page: number) {
    const totalPages = Math.ceil(
      this.allArticles.length / this.articlesPerPage
    );
    if (page >= 1 && page <= totalPages) {
      this.currentPage = page;
      this.updateArticles();
    }
  }

  getPageNumbers(): any[] {
    const totalPages = Math.ceil(
      this.allArticles.length / this.articlesPerPage
    );
    const pageNumbers = [];
    const maxPagesToShow = 10;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (this.currentPage <= 4) {
        // Show the first 6 pages
        for (let i = 1; i <= 6; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push(-1); // Ellipsis
        pageNumbers.push(totalPages);
      } else if (this.currentPage > 4 && this.currentPage < totalPages - 4) {
        // Show current page and surrounding pages
        pageNumbers.push(1);
        pageNumbers.push(2);
        pageNumbers.push(-1); // Ellipsis
        pageNumbers.push(this.currentPage - 1);
        pageNumbers.push(this.currentPage);
        pageNumbers.push(this.currentPage + 1);
        pageNumbers.push(-1); // Ellipsis
        pageNumbers.push(totalPages);
      } else {
        // Show the last 6 pages
        pageNumbers.push(1);
        pageNumbers.push(2);
        pageNumbers.push(-1); // Ellipsis
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      }
    }

    return pageNumbers;
  }

  onImageError(event: Event, index: number) {
    const replacementArticle = this.findReplacementArticle();
    if (replacementArticle) {
      this.articles[index] = replacementArticle;
    } else {
      const target = event.target as HTMLImageElement;
      target.src = this.defaultImage;
    }
  }

  findReplacementArticle(): any {
    const usedUrls = new Set(this.articles.map((article) => article.url));
    return this.allArticles.find((article) => !usedUrls.has(article.url));
  }

  navigateToBlog(route?: string, article?: any) {
    if (route === '/') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/blog-details'], {
        queryParams: { id: article?.uri, title: article?.title, image: article?.image },
      });
    }
  }

  getTotalPages(): number {
    return Math.ceil(this.allArticles.length / this.articlesPerPage);
  }
}
